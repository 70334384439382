// extracted by mini-css-extract-plugin
export var ComponentsGrid = "ApplicationsTemplate-module--ComponentsGrid--a2045";
export var container = "ApplicationsTemplate-module--container--2be0f";
export var description = "ApplicationsTemplate-module--description--e2fbb";
export var header = "ApplicationsTemplate-module--header--15411";
export var icon = "ApplicationsTemplate-module--icon--47212";
export var inlineTextLink = "ApplicationsTemplate-module--inlineTextLink--feaef";
export var loading = "ApplicationsTemplate-module--loading--11c2f";
export var pagination = "ApplicationsTemplate-module--pagination--6741f";
export var paginationContainer = "ApplicationsTemplate-module--paginationContainer--c29fa";
export var subHeading = "ApplicationsTemplate-module--subHeading--b2d24";
export var title = "ApplicationsTemplate-module--title--2d10c";